import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/sales/sprf',
        component: () => import('@/layout/SalesSPRFLayout.vue'),
        redirect: { name: 'WaitingApprovalView' },
        children: [
            {
                path: 'menunggu-approval',
                name: 'WaitingApprovalView',
                component: () => import('@/views/WaitingApprovalView.vue'),
            },
            {
                path: 'disetujui-belum-ada-quotation',
                name: 'ApprovedNoQuotationView',
                component: () => import('@/views/ApprovedNoQuotationView.vue'),
            },
            {
                path: 'disetujui',
                name: 'ApprovedView',
                component: () => import('@/views/ApprovedView.vue'),
            },
            {
                path: 'ditolak',
                name: 'RejectedView',
                component: () => import('@/views/RejectedView.vue'),
            },
            {
                path: 'dibatalkan',
                name: 'CanceledView',
                component: () => import('@/views/CanceledView.vue'),
            },
            {
                path: 'expired',
                name: 'ExpiredView',
                component: () => import('@/views/ExpiredView.vue'),
            },
            {
                path: 'sprf-discount-history',
                name: 'SPRFDiscountHistoryView',
                component: () => import('@/views/SPRFDiscountHistoryView.vue'),
            },
            {
                path: 'buat',
                name: 'CreateSPRFView',
                component: () => import('@/views/CreateSPRFView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
